import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { View, ScrollView, Text, Image, StyleSheet } from "react-native";
import Helper from "../../utils/Helper";
import apiDelivery from "../../Api/apiDelivery";
import TouchableButton from "../TouchableButton";
import { useOrderService } from "../../contexts/orderService";
import notPicture from "../../assets/images/notPicture.jpg";
import esgotado from "../../assets/images/esgotado.png";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { ImSearch } from "react-icons/im";
import TabSection from "../TabSection";
import "./style.css";
import { useColor } from "../../contexts/colors";
import { TextInput } from "react-native-web";

let loading = false;

export default function ListProducts({
  childrenComponents,
  restaurant,
  footerComponent,
  history,
}) {
  const {
    categories,
    updateCategories,
    company: companyData,
  } = useOrderService();

  const [filteredProduct, setFilteredProduct] = useState([]);

  const { backgroundColor, textColor } = useColor();
  let company = getSubDomain();

  useEffect(() => {
    if (categories.length === 0 && !loading) {
      getCategories();
    }
  }, []);

  function search(value) {
    if (value.length > 2) {
      const allProducts = categories.map((res) =>
        res.products.filter((item) =>
          Helper.sanitizeString(item.name).includes(
            Helper.sanitizeString(value)
          )
        )
      );
      setFilteredProduct(allProducts.filter((res) => res.length !== 0)[0]);
    } else {
      setFilteredProduct([]);
    }
  }

  async function getCategories() {
    loading = true;
    try {
      const {
        data: { response },
      } = await apiDelivery.get(`${company}/product`);

      loading = true;

      if (!response) return;

      localStorage.setItem(`products_${company}`, JSON.stringify(response));

      return updateCategories(response);
    } catch (err) {
      const storeProducts = localStorage.getItem(`products_${company}`);

      if (storeProducts) {
        updateCategories(JSON.parse(storeProducts));
      }
    }
  }

  function ContainerProductPlaceHolder() {
    return (
      <View style={styles.containerProducts}>
        <View style={{ flex: 1 }}>
          <Text style={[styles.itemTitle, { width: "80%" }]}>
            <Skeleton count={1} />
          </Text>
          <Text
            style={[styles.itemDescription, { width: "30%" }]}
            numberOfLines={2}
          >
            <Skeleton count={1} />
          </Text>
          <Text style={[styles.itemNoPrice, { width: "80%" }]}>
            <Skeleton count={1} />
          </Text>
          <Text style={[styles.itemPrice, { width: "30%" }]}>
            <Skeleton count={1} />
          </Text>
        </View>
        <View style={styles.logoItem}>
          <Text style={{ flex: 1 }}>
            <Skeleton count={1} height={85} />
          </Text>
        </View>
      </View>
    );
  }

  if (categories.length < 1)
    return (
      <View style={{ marginTop: 16 }}>
        <View style={[styles.tabBar, { padding: 0 }]}>
          <Text style={styles.textCategory}>
            <Skeleton count={1} height={50} />
          </Text>
        </View>
        <View style={[styles.itemContainer, { backgroundColor: "#dddddd" }]}>
          {ContainerProductPlaceHolder()}
          {ContainerProductPlaceHolder()}
        </View>
      </View>
    );

  return (
    <>
      <View style={styles.searchContainer}>
        <View
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <TextInput
            placeholder="Buscar produto"
            style={styles.input}
            onChange={(e) => search(e.target.value)}
          />
          <View style={styles.search}>
            <ImSearch />
          </View>
        </View>
      </View>
      {filteredProduct?.length == 0 ? (
        <TabSection
          childrenComponents={childrenComponents}
          footerComponent={footerComponent}
          list={categories
            .filter((category) => {
              return category.products.length !== 0;
            })
            .map((category, index) => {
              return {
                name: category.name,
                component: (
                  <View key={index}>
                    <View
                      style={[
                        styles.tabBar,
                        {
                          backgroundColor: backgroundColor,
                        },
                      ]}
                    >
                      <Text style={[styles.textCategory, { color: textColor }]}>
                        {category.name}
                      </Text>
                    </View>
                    <View>
                      {category.products.map((product, index) => {
                        return (
                          <View style={[styles.itemContainer]} key={index}>
                            <div className="list-product-item">
                              <TouchableButton
                                onPress={() => {
                                  if (!product.sold_off) {
                                    if (isEnvProduction()) {
                                      history.push(`/product`, {
                                        item: product,
                                        restaurant,
                                      });
                                    } else {
                                      history.push(`/${restaurant}/product/`, {
                                        item: product,
                                        restaurant,
                                      });
                                    }
                                  }
                                }}
                              >
                                <View style={styles.containerProducts}>
                                  <View style={{ flex: 1 }}>
                                    <Text style={styles.itemTitle}>
                                      {product.name}
                                    </Text>
                                    <Text
                                      style={styles.itemDescription}
                                      numberOfLines={2}
                                    >
                                      {product.description}
                                    </Text>
                                    {product.sale_price == 0 ? (
                                      <Text style={styles.itemNoPrice}>
                                        Clique aqui para mais detalhes
                                      </Text>
                                    ) : (
                                      <View>
                                        {Number(product.sale_price) !== 0 &&
                                        Number(product.sale_price) <
                                          Number(product.normal_price) ? (
                                          <View
                                            style={styles.discountContainer}
                                          >
                                            <Text
                                              style={styles.discountItemPrice}
                                            >
                                              R$
                                              {Helper.maskMoney(
                                                parseFloat(product.normal_price)
                                              )}
                                            </Text>
                                            <Text style={styles.discountPrice}>
                                              R$
                                              {Helper.maskMoney(
                                                parseFloat(product.sale_price)
                                              )}
                                            </Text>
                                          </View>
                                        ) : (
                                          <Text style={styles.itemPrice}>
                                            R$
                                            {Helper.maskMoney(
                                              parseFloat(product.sale_price)
                                            )}
                                          </Text>
                                        )}
                                      </View>
                                    )}
                                  </View>
                                  <View style={{ position: "relative" }}>
                                    <View
                                      style={
                                        ({ position: "relative" },
                                        !product.sold_off
                                          ? {}
                                          : { opacity: 0.3 })
                                      }
                                    >
                                      <Image
                                        resizeMode="contain"
                                        style={styles.logoItem}
                                        source={{
                                          uri: !product.picture
                                            ? notPicture
                                            : product.picture,
                                        }}
                                      />
                                    </View>
                                    <View style={{ position: "absolute" }}>
                                      <Image
                                        resizeMode="contain"
                                        style={styles.logoItem}
                                        source={{
                                          uri: !product.sold_off
                                            ? ""
                                            : esgotado,
                                        }}
                                      />
                                    </View>
                                  </View>
                                </View>
                              </TouchableButton>
                            </div>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ),
              };
            })}
        />
      ) : (
        <>
          <View
            style={[
              styles.tabBar,
              {
                backgroundColor: backgroundColor,
                marginTop: "15px",
              },
            ]}
          >
            <Text style={[styles.textCategory, { color: textColor }]}>
              Resultado
            </Text>
          </View>
          <View>
            {filteredProduct?.map((product, index) => {
              return (
                <View style={[styles.itemContainer]} key={index}>
                  <div className="list-product-item">
                    <TouchableButton
                      onPress={() => {
                        if (!product.sold_off) {
                          if (isEnvProduction()) {
                            history.push(`/product`, {
                              item: product,
                              restaurant,
                            });
                          } else {
                            history.push(`/${restaurant}/product/`, {
                              item: product,
                              restaurant,
                            });
                          }
                        }
                      }}
                    >
                      <View style={styles.containerProducts}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.itemTitle}>{product.name}</Text>
                          <Text
                            style={styles.itemDescription}
                            numberOfLines={2}
                          >
                            {product.description}
                          </Text>
                          {product.sale_price == 0 ? (
                            <Text style={styles.itemNoPrice}>
                              Clique aqui para mais detalhes
                            </Text>
                          ) : (
                            <Text style={styles.itemPrice}>
                              R$
                              {Helper.maskMoney(parseFloat(product.sale_price))}
                            </Text>
                          )}
                        </View>
                        <View style={{ position: "relative" }}>
                          <View
                            style={
                              ({ position: "relative" },
                              !product.sold_off ? {} : { opacity: 0.3 })
                            }
                          >
                            <Image
                              resizeMode="contain"
                              style={styles.logoItem}
                              source={{
                                uri: !product.picture
                                  ? notPicture
                                  : product.picture,
                              }}
                            />
                          </View>
                          <View style={{ position: "absolute" }}>
                            <Image
                              resizeMode="contain"
                              style={styles.logoItem}
                              source={{
                                uri: !product.sold_off ? "" : esgotado,
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </TouchableButton>
                  </div>
                </View>
              );
            })}
          </View>
          <View style={styles.footer}>{footerComponent}</View>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabBar: {
    borderBottomColor: "#f4f4f4",
    borderBottomWidth: 1,
    padding: 20,
  },
  itemContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  itemTitle: {
    flex: 1,
    fontSize: 18,
    color: "#131313",
  },
  itemPrice: {
    fontSize: 18,
    color: "#131313",
    marginVertical: 15,
  },
  discountContainer: {
    display: "flex",
    flexDirection: "row",
  },
  discountPrice: {
    fontSize: 18,
    color: "#00a550",
    marginVertical: 15,
    marginLeft: 8,
  },
  discountItemPrice: {
    textDecorationLine: "line-through",
    fontSize: 18,
    color: "#909090",
    marginVertical: 15,
  },
  itemNoPrice: {
    color: "rgb(133, 133, 133)",
  },
  contentDescriptionImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemDescription: {
    color: "#b6b6b6",
    fontSize: 12,
    flex: 1,
  },
  itemRow: {
    flexDirection: "row",
  },
  logoItem: {
    width: 75,
    height: 75,
    flexDirection: "row",
    borderRadius: 5,
  },
  textCategory: {
    fontSize: 16,
    fontWeight: "500px",
  },
  containerProducts: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchContainer: {
    width: "95%",
    marginRight: "2.5%",
    marginLeft: "2.5%",
    marginTop: "10px",
  },
  input: {
    padding: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    width: "100%",
  },
  search: {
    position: "absolute",
    height: "100%",
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    marginTop: 70,
  },
});
