import React, { useEffect, useState } from "react";
import { StyleSheet, SafeAreaView, Text, Image } from "react-native";
import Bag from "../../components/Bag";
import BagHonestMarket from "../../components/BagHonestMarket";
import {
  AiOutlineUser,
  AiOutlineCloseCircle,
  AiOutlineWhatsApp,
  AiOutlineCreditCard,
} from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { MdRestaurantMenu, MdPhone } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { useOrderService } from "../../contexts/orderService";
import BannerProduct from "../../components/BannerProduct/BannerProduct";
import ModalPromotionBanner from "../../components/ModalPromotionBanner/ModalPromotionBanner";
import ListProducts from "../../components/ListProducts";
import Colors from "../../components/Colors";
import NotFound from "../../components/NotFound/NotFound";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import Sidebar from "react-sidebar";
import apiDelivery from "../../Api/apiDelivery";
import { TouchableOpacity, View } from "react-native-web";
import { Linking } from "react-native";
import FloatingButton from "../../components/FloatingButton";
import ForceUpdateModal from "../../components/ForceUpdateModal";
import { useColor } from "../../contexts/colors";
import "./styles.css";
import { useCookies } from "react-cookie";

export default function Restaurant({ history }) {
  const { menuColor, menuTextColor, backgroundColor, textColor } = useColor();
  const [cookies, setCookies] = useCookies(["user"]);
  let loading = false;
  let iconsSize = 30;
  const [locationRequested, setLocationRequested] = useState(false);
  const { bag } = useOrderService();
  const {
    neighborhood,
    updateNeighborhood,
    getLocation,
    toggleMenu,
    setToggleMenu,
    setVoucherValue,
    company: companySelected,
    updateAllNeighborhoods,
    setDetailCompany,
    getDataCompany,
    setHonestMarket,
    honestMarket
  } = useOrderService();
  let company = getSubDomain();
  const [error, setError] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [doUpdate, setDoUpdate] = useState(false);
  const pendingUpdate = () => {
    apiDelivery.get(`/${company}/version_update`).then((response) => {
      setCurrentVersion(response.data.response.version);

      const responseVersion = response.data.response.version;
      const lastVersion = localStorage.getItem(`version_zappedis`);

      if (lastVersion == null) {
        localStorage.setItem(`version_zappedis`, responseVersion);
      }

      if (responseVersion != lastVersion && lastVersion != null) {
        setDoUpdate(true);
      }
    });
  };

  useEffect(() => {
    pendingUpdate();
    setToggleMenu(false);
    setVoucherValue([]);
  }, []);

  useEffect(() => {
    async function getCategories() {
      const data = await getDataCompany(company);
      if (!data) setError(true);
        setHonestMarket(data?.honest_market);
      setDetailCompany(data);
    }

    if (companySelected == null && !error) {
      getCategories();
    }
  }, []);

  useEffect(() => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      let crd = pos.coords;
      getLocation(crd.latitude, crd.longitude, crd.accuracy);
      setLocationRequested(true);
    }

    navigator.geolocation.getCurrentPosition(
      success,
      () => setLocationRequested(true),
      options
    );

    async function getNeighborhoodData() {
      try {
        const {
          data: { response },
        } = await apiDelivery.get(`${company}/neighborhood`);

        loading = true;

        if (!response) {
          return;
        }

        localStorage.setItem(
          `neighborhood_${company}`,
          JSON.stringify(response)
        );

        updateNeighborhood(response);
        updateAllNeighborhoods(response);

        const expires = new Date();
        expires.setDate(expires.getDate() + 365);

        response.find((item) => {
          if (cookies.user) {
            let neighborhoodUser = JSON.parse(cookies.user.neighborhood);
            if (item.neighborhood == neighborhoodUser.neighborhood) {
              return setCookies(
                "user",
                JSON.stringify({
                  id: cookies.user.id,
                  name: cookies.user.name,
                  address: cookies.user.address,
                  complement: cookies.user.complement,
                  referencePoint: cookies.user.referencePoint,
                  numberAddress: cookies.user.numberAddress,
                  email: cookies.user.email,
                  phone: cookies.user.phone,
                  neighborhood: JSON.stringify(item),
                }),
                {
                  expires,
                  maxAge: 63072000,
                }
              );
            }
          }
        });
      } catch (err) {
        const allocateNeighborhood = localStorage.getItem(
          `neighborhood_${company}`
        );
        if (allocateNeighborhood) {
          updateNeighborhood(JSON.parse(allocateNeighborhood));
          updateAllNeighborhoods(JSON.parse(allocateNeighborhood));
        }
      }
    }
    getNeighborhoodData();
  }, []);

  const responseCompany = JSON.parse(
    localStorage.getItem(`company_${company}`)
  );

  function CheckScreen() {
    if (error) return <NotFound />;
    return (
      <>
        <ForceUpdateModal doUpdate={doUpdate} currentVersion={currentVersion} />
        {!doUpdate && <ModalPromotionBanner />}
        <BannerProduct />
        <FloatingButton phoneNumber={responseCompany.phone_whatsapp} />
        <ListProducts
          restaurant={company}
          isBagActive={bag.length > 0}
          history={history}
          footerComponent={bag.length > 0 ? <Bag history={history} /> : null }
        />
      </>
    );
  }

  if (error) {
    return <NotFound />;
  }

  if (!locationRequested || !companySelected) {
    return (
      <div id="preloader">
        <div className="loads">
          <div className="icon"></div>
        </div>
      </div>
    );
  }

  function SidebarContent() {
    const establishmentAdress = responseCompany.address.address.split(",");
    const adressStreet = establishmentAdress[0].replace(" ", "+");
    const adressNumber = establishmentAdress[1];

    return (
      <>
        <View
          style={[
            styles.sidebarTopTitle,
            { backgroundColor: menuColor, color: menuTextColor },
          ]}
        >
          <b>Menu</b>
          <TouchableOpacity onClick={() => setToggleMenu(false)}>
            <AiOutlineCloseCircle size={20} />
          </TouchableOpacity>
        </View>

        <View style={{ alignItems: "center" }}>
          <Image
            style={{
              width: "90px",
              height: "90px",
              borderRadius: "50%",
              margin: "20px",
            }}
            source={responseCompany && responseCompany.logo_picture}
          />
          <Text style={styles.addresLabel}>
            {establishmentAdress.toString()}
          </Text>
        </View>

        <TouchableOpacity
          onPress={() => {
            if (isEnvProduction()) {
              history.push(`/profile/`);
            } else {
              history.push(`/${company}/profile/`);
            }
          }}
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <AiOutlineUser size={iconsSize} />
              <Text style={styles.labelItem}>Pefil</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            if (isEnvProduction()) {
              history.push(`/myOrders/`);
            } else {
              history.push(`/${company}/myOrders/`);
            }
          }}
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <MdRestaurantMenu size={iconsSize} />
              <Text style={styles.labelItem}>Meus Pedidos</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onClick={() =>
            Linking.openURL(`tel:+${responseCompany.phone_whatsapp}`)
          }
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <MdPhone size={iconsSize} />
              <Text style={styles.labelItem}>Ligar</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onClick={() =>
            Linking.openURL(
              `https://api.whatsapp.com/send?phone=55${responseCompany.phone_whatsapp}`
            )
          }
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <AiOutlineWhatsApp size={iconsSize} />
              <Text style={styles.labelItem}>Fale Conosco</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onClick={() =>
            Linking.openURL(
              `https://www.google.com/maps?q=${adressStreet},+${adressNumber}`
            )
          }
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <GrLocation size={iconsSize} />
              <Text style={styles.labelItem}>Localização</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onClick={() => {
            if (isEnvProduction()) {
              history.push(`/payments`);
            } else {
              history.push(`/${company}/payments/`);
            }
          }}
        >
          <View style={styles.sidebarContent}>
            <View style={styles.containerIconMenu}>
              <AiOutlineCreditCard size={iconsSize} />
              <Text style={styles.labelItem}>Cartão</Text>
            </View>
            <IoIosArrowForward color={"#6B6B6B"} />
          </View>
        </TouchableOpacity>
      </>
    );
  }

  return (
    <>
      <Sidebar
        sidebar={SidebarContent()}
        open={toggleMenu}
        onSetOpen={() => setToggleMenu(false)}
        styles={{
          sidebar: {
            background: "white",
            width: "70%",
            height: "100%",
            position: "fixed",
          },
        }}
      />
      <SafeAreaView style={styles.container}>
        {companySelected == null ? null : <CheckScreen />}
      </SafeAreaView>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    width: "100%",
  },
  contentContainer: {
    flexGrow: 1,
  },
  navContainer: {
    height: 70,
    marginHorizontal: 10,
  },
  btnBackNavBar: {
    marginTop: 5,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  titleStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
  },
  titleContent: {
    fontSize: 18,
  },
  subTitleContent: {
    color: "#666",
  },
  ratingTitlleContent: {
    textAlign: "right",
    color: "#ffab00",
  },
  contentTimeDelivery: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    padding: 10,
    borderColor: "#ddd",
    marginVertical: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  contentItemTimeDelivery: {
    marginHorizontal: 10,
  },
  listItems: {
    flex: 1,
    backgroundColor: "#fff",
  },
  contentItem: {
    marginHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    alignItems: "center",
    marginBottom: 10,
  },
  detailsItem: {
    flex: 1,
    paddingHorizontal: 10,
  },
  addresLabel: {
    textAlign: "center",
    color: "#666",
  },
  titleItem: {
    fontSize: 14,
    marginBottom: 5,
  },
  descriptionItem: {
    color: "#666",
  },
  sidebarTopTitle: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.PRIMARY,
    color: "white",
  },
  sidebarContent: {
    height: 50,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  labelItem: {
    margin: 10,
    fontWeight: "bold",
  },
  containerIconMenu: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 5,
  },
});
