import React, { useContext, createContext, useState, useEffect } from "react";
import apiDelivery from "../Api/apiDelivery";
import {getToken} from "../services/firebase-messaging-sw"

const OrderServiceContext = createContext({
  bag: [],
  visible: false,
  establishment: null,
  paymentForm: null,
  changeMoney: 0,
  neighborhood: null,
  company: null,
  orderMode: null,
  isFreeShipping: false,
  getOrderCode: () => {},
  addItem: () => {},
  setVisibleBag: () => {},
  clearBag: () => {},
  addEstablishment: () => {},
  editItem: () => {},
  removeItem: (id) => {},
  selectedPaymentForm: () => {},
  SelectedChangeMoney: () => {},
  clearOrder: () => {},
  changeNeighborhood: () => {},
  setDetailCompany: () => {},
  getDataCompany: (name) => {},
});

export const OrderServiceProvider = ({ children }) => {
  const [establishment, setEstablishment] = useState(null);
  const [bag, setBag] = useState([]);
  const [visible, setVisible] = useState(false);
  const [paymentForm, setPaymentForm] = useState(null);
  const [changeMoney, setChangeMoney] = useState(0);
  const [isHigherValue,setIsHigherValue] = useState(false);
  const [showBeforeUnload, setShowBeforeUnload] = useState(true);
  const [neighborhood, setNeighborhood] = useState([]);
  const [voucherValue, setVoucherValue] = useState([]);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [totalVoucher, setTotalVoucher] = useState(0);
  const [voucherSelected, setVoucherSelected] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [accuracy, setAccuracy] = useState();
  const [address, setAddress] = useState("");
  const [allNeighborhoods, setAllNeighborhoods] = useState([]);
  const [modalPromotion, setModalPromotion] = useState(true);
  const [company, setCompany] = useState(null);
  const [categories, setCategories] = useState([]);
  const [orderMode, setOrderMode] = useState(null);
  const [isFreeShipping, setIsFreeShipping] = useState(false);
  const [couponText, setCouponText] = useState();
  const [visibleModalChange, setVisibleModalChange] = useState(false);
  const [redirectMenu, setRedirectMenu] = useState(false);
  const [openOrder, setOpenOrder] = useState(null);
  const [honestMarket, setHonestMarket] = useState(false);
  const [errorScanner, setErrorScanner] = useState(false);

  useEffect(() => {
    setIsFreeShipping(
      bag.filter(({ item }) => {
        return !item.free_shipping;
      }).length === 0
    );
  }, [bag]);

  async function getDataCompany(name) {
    try {
      const {
        data: { response },
      } = await apiDelivery.get(`${name}`);

      localStorage.setItem(`company_${name}`, JSON.stringify(response));
      setVisibleBag(true);
      return response;
    } catch (err) {
      const storageCompany = localStorage.getItem(`company_${name}`);
      if (storageCompany) {
        return JSON.parse(storageCompany);
      }
      return null;
    }
  }

  function changeOrderMode(value) {
    setOrderMode(value);
  }

  function updateCategories(data) {
    setCategories(data);
  }
  function getLocation(lat,long,acc) {
    setLatitude(lat);
    setLongitude(long)
    setAccuracy(acc)
  }

  function updateNeighborhood(data) {
    setNeighborhood(data);
  }

  function updateAllNeighborhoods(data) {
    setAllNeighborhoods(data);
  }

  function addItem(item, amount, complements, observation, higherValue) {
    const id = new Date().getTime();
    setBag([...bag, { id, item, amount, complements, observation, higherValue }]);
  }

  function addItems(items) {
    setBag([...bag, ...items]);
  }

  function addEstablishment(establishment) {
    setEstablishment(establishment);
  }

  function clearOrder() {
    setBag([]);
    setOrderMode(null);
  }

  function clearBag() {
    setEstablishment(null);
    setBag([]);
    setPaymentForm(null);
  }

  function SelectedChangeMoney(payment) {
    setChangeMoney(payment);
  }

  function setDetailCompany(data) {
    setCompany(data);
  }

  function selectedPaymentForm(payment) {
    setPaymentForm(payment);
  }

  function setVisibleBag(status) {
    setVisible(status);
  }

  function editItem(id, item, amount, complements, observation) {
    setBag([
      ...bag.map((group) => {
        if (group.id !== id) return group;

        return { id, item, amount, complements, observation };
      }),
    ]);
  }

  function changeVisibleModalPromotion(status) {
    setModalPromotion(status);
  }

  function clearVoucher() {
    setTotalVoucher(0);
    setVoucherSelected(false);
    setVoucherValue([]);
  };

  function changeNeighborhood(value) {
    setNeighborhood(value);
  }

  function removeItem(id) {
    const newBag = bag.filter((product) => {
      return product.id !== id;
    });

    if (newBag.length === 0) setEstablishment(null);

    setBag([...newBag]);
  }

  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isEdgeOrChrome = !isIE && !!window.StyleMedia;

  return (
    <OrderServiceContext.Provider
      value={{
        bag,
        modalPromotion,
        visible,
        establishment,
        paymentForm,
        changeMoney,
        neighborhood,
        latitude,
        longitude,
        company,
        orderMode,
        showBeforeUnload,
        categories,
        isFreeShipping,
        allNeighborhoods,
        visibleModalChange,
        couponText,
        totalVoucher,
        voucherValue,
        voucherSelected,
        toggleMenu,
        accuracy,
        address,
        isHigherValue,
        isSafari,
        isFirefox,
        isEdgeOrChrome,
        isIE,
        redirectMenu,
        openOrder,
        getToken,
        setIsHigherValue,
        setTotalVoucher,
        setAddress,
        setToggleMenu,
        setShowBeforeUnload,
        setIsFreeShipping,
        setVoucherSelected,
        setVoucherValue,
        clearVoucher,
        setEstablishment,
        setCouponText,
        updateNeighborhood,
        getLocation,
        setNeighborhood,
        setAllNeighborhoods,
        updateAllNeighborhoods,
        setVisibleModalChange,
        addItem,
        addItems,
        setVisibleBag,
        clearBag,
        addEstablishment,
        selectedPaymentForm,
        SelectedChangeMoney,
        editItem,
        removeItem,
        clearOrder,
        changeVisibleModalPromotion,
        changeNeighborhood,
        setDetailCompany,
        changeOrderMode,
        updateCategories,
        getDataCompany,
        setRedirectMenu,
        setOpenOrder,
        setCompany,
        honestMarket,
        setHonestMarket,
        errorScanner,
        setErrorScanner
      }}
    >
      {children}
    </OrderServiceContext.Provider>
  );
};

export const useOrderService = () => {
  const context = useContext(OrderServiceContext);

  return context;
};
