import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Colors from "../Colors";
import { TextInput } from "react-native";
import SelectNeighborhoods from "../SeletectNeighborhoods/SelectNeighborhoods";
import Modal from "modal-react-native-web";
import NavBar from "../NavBar";
import { useCookies } from "react-cookie";
import ModalNullNeightborhood from "../ModalNullNeightborhood/ModalNullNeightborhood";
import { useOrderService } from "../../contexts/orderService";
import InputPhoneMask from "../InputPhoneMask/InputPhoneMask";
import Helper from "../../utils/Helper";
import { toast, ToastContainer } from "react-toastify";
import apiDelivery from "../../Api/apiDelivery";
import ModalVerificationUser from "../ModalVerificationUser/ModalVerificationUser";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useColor } from "../../contexts/colors";

export default function FormSign(props) {
  const {
    neighborhood,
    getToken,
    isEdgeOrChrome,
    isFirefox,
    company: companySelected,
    changeNeighborhood,
  } = useOrderService();

  const {
    buttonColor,
    buttonTextColor
  } = useColor();

  const [cookies, setCookies] = useCookies(["user"]);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [numberAddress, setNumberAddress] = useState("");
  const [referencePoint, setReferencePoint] = useState("");
  const [user, setUser] = useState([]);
  const [token, setToken] = useState(null);
  const [visibleModalNull, setVisibleModalNull] = useState(false);
  const [visibleModalUser, SetVisibleModalUser] = useState(false);
  const [userId, setUserId] = useState(0);
  let company = getSubDomain();
  const honestMarket = companySelected.honest_market

  useEffect(() => {
    if (cookies.user) {
      setName(cookies.user.name);
      setAddress(cookies.user.address);
      setComplement(cookies.user.complement);
      setNumberAddress(cookies.user.numberAddress);
      setReferencePoint(cookies.user.referencePoint);
      setPhone(cookies.user.phone);
      setEmail(cookies.user.email);
      if (cookies.user.neighborhood)
        changeNeighborhood(JSON.parse(cookies.user.neighborhood));
      setUser(cookies.user);
    }
  }, [cookies.user]);

  async function validateUser() {
    return await apiDelivery.get(
      `${company}/customer/${Helper.onlyNumbers(phone)}`
    );
  }

  useEffect(() => {
    if (isFirefox || isEdgeOrChrome) {
      getToken().then((e) => setToken(e));
    }
  }, []);

  function validateForm() {
    if (name === "" || name == null) {
      toast.dark("Nome Obrigatório");
      return false;
    }
    if (address === "" || address == null) {
      toast.dark("Endereço Obrigatório");
      return false;
    }
    if (numberAddress === "" || numberAddress == null) {
      toast.dark("Número Obrigatório");
      return false;
    }
    if (neighborhood === null) {
      toast.dark("Bairro Obrigatório");
      return false;
    }
    if (phone === "") {
      toast.dark("Telefone Obrigatório");
      return false;
    }
    if (!Helper.validatePhone(phone)) {
      toast.dark("Telefone inválido");
      return false;
    }

    if (email.length > 0) {
      if (!Helper.isEmailValid(email)) {
        toast.dark("E-mail invalido");
        return false;
      }
    }

    return true;
  }

  function goBack() {
    let redirect = "/";

    if (!isEnvProduction()) {
      redirect = `/${company}`;
    }

    if (onClose != undefined) {
      return onClose();
    }

    if (onClose === undefined) {
      return history.push(redirect);
    }
  }

  const putOnCookies = (id) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 365);

      setCookies(
        "user",
        JSON.stringify({
          id: id,
          name,
          address,
          complement,
          referencePoint,
          numberAddress,
          email,
          phone,
          neighborhood: JSON.stringify(neighborhood),
        }),
        {
          expires,
          maxAge: 63072000,
        }
      )
  }

  async function putDataUser() {
      if (
        cookies.user.name === name &&
        cookies.user.address === address &&
        cookies.user.neighborhood === neighborhood &&
        cookies.user.phone === phone &&
        cookies.user.email === email 
      ) {
        return;
      }
    try {
      apiDelivery.put(`${company}/customer/${user.id}`, {
        _method: "PUT",
        id: user.id,
        full_name: name,
        address: address,
        complement: complement,
        number: numberAddress,
        reference_point: referencePoint,
        device_token_id: token,
        phone_contact: Helper.onlyNumbers(phone),
        email: email,
        neighborhood_id: neighborhood.id,
        company_id: companySelected.id,
      }).then((res) => {
        if (res.status === 200) {
          putOnCookies(userId);
        }
      })
    } catch (error) {
      return true;
    }
    return false;
  }

  async function saveDataUser() {
    return apiDelivery.post(`${company}/customer`, {
      full_name: name,
      address: address,
      complement: complement,
      number: numberAddress,
      reference_point: referencePoint,
      phone_contact: Helper.onlyNumbers(phone),
      email: email,
      device_token_id: token,
      neighborhood_id: neighborhood.id,
      company_id: companySelected.id,
    });
  }
  const { visible, history, onClose } = props;

  return (
    <Modal
      visible={visible}
      animationType="slide"
      onRequestClose={() => {
        onClose();
      }}
    >
      <View style={styles.container}>
        <NavBar
          history={history}
          onBack={() => {
            onClose();
          }}
        />
        <ModalNullNeightborhood
          onClose={() => {
            setVisibleModalNull(false);
          }}
          company={companySelected}
          visible={visibleModalNull}
        />
        <ScrollView>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Nome</Text>
            <TextInput
              style={styles.input}
              value={name}
              onChangeText={(text) => setName(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Endereço</Text>
            <TextInput
              style={styles.input}
              value={address}
              onChangeText={(text) => setAddress(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>Complemento</Text>
              <Text style={styles.labelOption}>  (Opcional)</Text>
            </View>

            <TextInput
              style={styles.input}
              value={complement}
              onChangeText={(text) => setComplement(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Número</Text>
            <TextInput
              keyboardType="number-pad"
              style={styles.input}
              value={numberAddress}
              onChangeText={(text) => setNumberAddress(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>Ponto de Referência</Text>
              <Text style={styles.labelOption}>  (Opcional)</Text>
            </View>

            <TextInput
              style={styles.input}
              value={referencePoint}
              onChangeText={(text) => setReferencePoint(text)}
            />
          </View>
          <View>
            <Text style={styles.SelectNeighborhoodsLabel}>Bairro</Text>
            <SelectNeighborhoods honestMarket={honestMarket} value={neighborhood} />
            {!honestMarket ?
             <TouchableOpacity>
              <Text
                onPress={() => {
                  setVisibleModalNull(true);
                }}
                style={[styles.linkNeighBorHoods, {color: buttonColor}]}
              >
                Meu bairro não consta na lista
              </Text>
            </TouchableOpacity> :
            ''
            }
          </View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Telefone</Text>
            <InputPhoneMask
              // keyboardType=""
              style={styles.input}
              value={phone}
              onChangeText={(text) => setPhone(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>E-mail</Text>
              <Text style={styles.labelOption}>  (Opcional)</Text>
            </View>
            <TextInput
              keyboardType="email-address"
              style={styles.input}
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              if (!validateForm()) return;
              SetVisibleModalUser(false);

              const expires = new Date();
              expires.setDate(expires.getDate() + 365);

              validateUser()
                .then(({ data: { response } }) => {
                  if (response !== null) {
                    return response;
                  }
                  SetVisibleModalUser(false);
                  saveDataUser()
                    .then(({ data: { response } }) => {
                      setCookies(
                        "user",
                        JSON.stringify({
                          id: response.id,
                          name,
                          address,
                          complement,
                          referencePoint,
                          numberAddress,
                          email,
                          phone,
                          neighborhood: JSON.stringify(neighborhood),
                        }),
                        {
                          expires,
                          maxAge: 63072000,
                        }
                      );
                      onClose();
                    })
                    .catch((result) => {
                      toast.dark("E-mail já cadastrado.");
                    });
                })
                .then((response) => {
                  if (response == null) throw null;
                    setUserId(response.id);
                    SetVisibleModalUser(true);
                    if (!cookies.user) {
                      putOnCookies(response.id)
                    }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            <View style={[styles.btnContainer, {backgroundColor: buttonColor}]}>
              <Text style={[styles.btnText, {color: buttonTextColor}]}>Salvar</Text>
            </View>
          </TouchableOpacity>
          <ModalVerificationUser
            handleAccept={() => {
              goBack();
              putDataUser();
            }}
            company={companySelected}
            userData={user}
            visible={visibleModalUser}
          />
        </ScrollView>
        <ToastContainer hideProgressBar={true} pauseOnHover />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  labelGroup:{
    flexDirection: "row", 
    alignItems: 'center',  
    marginBottom: 10 
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  containerInput: {
    padding: 10,
  },
  labelInput: {   
    fontSize: 14,
  },
  labelOption: {
    fontSize: 10,
    color: "#8F8F8F"
  },
  SelectNeighborhoodsLabel: {
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 14,
  },

  input: {
    padding: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
  },
  btnContainer: {
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    padding: 20,
    marginTop: 5,
    marginHorizontal: 10,
    borderRadius: 5,
  },
  btnText: {
    color: "#fff",
    fontSize: 16,
  },
  linkNeighBorHoods: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 14,
    color:  Colors.PRIMARY,
  },
});
