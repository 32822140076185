import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Share,
  Linking,
  KeyboardAvoidingView,
} from "react-native";
import { FaEllipsisV } from "react-icons/fa";
import Colors from "../../components/Colors";
import OptionItemModal from "../../components/OptionItemModal";
import Helper from "../../utils/Helper";
import { useOrderService } from "../../contexts/orderService";
import FormSign from "../../components/FormSign/FormSign";
import OptionsPayment from "../../components/OptionsPayment";
import msgWhats from "../../components/MsgWhats/MsgWhats";
import { useCookies } from "react-cookie";
import NavBar from "../../components/NavBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import apiDelivery from "../../Api/apiDelivery";
import { useHistory } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import SelectDeliveryMethod from "../../components/SelectDeliveryMethod/SelectDeliveryMethod";
import Change from "../../components/Change/Change";
import ModalChange from "../../components/Change/ModalChange";
import ModalConfirmationOrder from "../../components/ModalConfirmationOrder";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { ActivityIndicator } from "react-native";
import "./styles.css";
import Voucher from "../../components/Voucher";
import sendNotification from "../../utils/sendNotification";
import { useColor } from "../../contexts/colors";
import NewCard from "../../components/NewCard";
import CardPayment from "../../components/CardsPayment";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import Preloader from "../../components/Preloader";

const windowHeight = Dimensions.get("window").height;


export default function BagDetail({ route, navigation }) {
  const {
    bag,
    neighborhood,
    removeItem,
    paymentForm,
    changeMoney,
    latitude,
    longitude,
    accuracy,
    orderMode,
    changeOrderMode,
    company: companyData,
    isFreeShipping,
    setIsFreeShipping,
    clearBag,
    setTotalVoucher,
    voucherValue,
    setVoucherValue,
    setShowBeforeUnload,
    voucherSelected,
    setEstablishment,
    isHigherValue,
    clearVoucher,
    totalVoucher,
    getOrderCode,
    honestMarket,
    SelectedChangeMoney
  } = useOrderService();
  const { buttonColor, buttonTextColor, backgroundColor, textColor } =
    useColor();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [visibleModalPayment, setVisibleModalPayment] = useState(false);
  const [visibleModalVoucher, setVisibleModalVoucher] = useState(false);
  const [visibleFormSign, setVisibleFormSign] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [isVisibleNewCard, setIsVisibleNewCards] = useState(false);
  const [loading, setLoading] = useState();
  const [pickupOnEstablishment, setPickupOnEstablishment] = useState(false);
  const [phone, setPhone] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);
  const [creditCard, setCreditCard] = useState([]);
  const [useCard, setUseCard] = useState(false);
  const [user, setUser] = useState(null);
  const [searchCard, setSearchCard] = useState(false);
  let company = getSubDomain();
  let cards = JSON.parse(localStorage.getItem("history_card"));
  const isExternal = bag.find((value) => value.item.external === false);
  
  useEffect(() => {
    if (bag.length === 0) {
      history.goBack();
    }

    function getUserData() {
      if (!cookies.user) {
        setVisibleFormSign(true);
      } else {
        setUser(cookies.user);
      }
    }

    getUserData();
  }, [cookies]);

  useEffect(() => {
    handleCardPayment();
  }, [paymentForm]);

  useEffect(() => {
    cards = JSON.parse(localStorage.getItem("history_card"));
    setCreditCard(cards);
    handleCardPayment();
    setSearchCard(false);
  }, [searchCard, isVisibleNewCard]);

  if (honestMarket) {
    changeOrderMode('0')
    SelectedChangeMoney(0);
  }

  const handleCardPayment = () => {
    setSearchCard(true);
    if (paymentForm != null) {
      if (
        Helper.sanitizeString(paymentForm.description).includes("credito") &&
        paymentForm?.activate_acquirer
      ) {
        if ((cards?.length !== 0) & (cards !== null)) {
          cards.filter((item) => {
            if (item.active) {
              if (paymentForm?.activate_acquirer) {
                setUseCard(true);
                return setCreditCard(item);
              }
            }
            setUseCard(true);
            return item.length !== 0 ? "" : setIsVisibleNewCards(true);
          });
          setUseCard(true);
          return !Boolean(creditCard) && setIsVisibleNewCards(true);
        }
        setUseCard(true);
        return setIsVisibleNewCards(true);
      }
      setUseCard(false);
    }
  };

  useEffect(() => {
    if (cookies.user) {
      setPhone(cookies.user.phone);
    }
  }, [cookies.user]);

  useEffect(() => {
    getNeighborhoodActual();
  }, []);

  async function getNeighborhoodActual() {
    const {
      data: { response },
    } = await apiDelivery.get(`${company}/neighborhood`);

    const expires = new Date();
    expires.setDate(expires.getDate() + 365);

    response.find((item) => {
      if (cookies.user) {
        let neighborhoodUser = JSON.parse(cookies.user.neighborhood);
        if (item.neighborhood == neighborhoodUser.neighborhood) {
          return setCookies(
            "user",
            JSON.stringify({
              id: cookies.user.id,
              name: cookies.user.name,
              address: cookies.user.address,
              complement: cookies.user.complement,
              referencePoint: cookies.user.referencePoint,
              numberAddress: cookies.user.numberAddress,
              email: cookies.user.email,
              phone: cookies.user.phone,
              neighborhood: JSON.stringify(item),
            }),
            {
              expires,
              maxAge: 63072000,
            }
          );
        }
      }
    });
  }

  async function getVoucherData() {
    setTotalVoucher(0);
    setVoucherValue([]);

    const {
      data: { response },
    } = await apiDelivery.get(
      `${company}/customer/${Helper.onlyNumbers(phone)}/voucher`
    );

    response.map((product, index) => {
      let value = JSON.parse(product.available_value);
      voucherValue.push(value);
    });
    if (voucherValue !== null) {
      setTotalVoucher(
        voucherValue.reduce((total, currentElement) => {
          return total + currentElement;
        }, 0)
      );
    }
  }

  useEffect(() => {
    if (phone.length > 0) {
      getVoucherData();
    }
    if (orderMode === "0") {
      setPickupOnEstablishment(true);
    }
    if (orderMode === "1" && !isFreeShipping) {
      setPickupOnEstablishment(false);
    }
    if (orderMode === "1" && isFreeShipping) {
      setPickupOnEstablishment(true);
    }
  }, [phone, orderMode]);

  function move() {
    let elem = document.getElementById("greenBar");
    let stepValue = 0;
    let id = setInterval(frame, 500);

    function frame() {
      if (stepValue >= 100) {
        clearInterval(id);
      } else {
        elem.style.width = stepValue + 10 + "%";
        elem.innerHTML = stepValue + 10 + "%";
        stepValue = stepValue + 10;
      }
    }
  }

  async function sendOrderCode() {
    setConfirmation(false);
    setLoading(true);
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });
    let items = bag.map((item, index) => {
      const verifyIsRedo = () => {
        if (item.complements.length > 0) {
          if (item.complements[0].items) {
            return Helper.getComplementByQuantity(item.complements);
          }

          if (!item.complements[0].items) {
            return item.complements;
          }
        }
        return item.complements;
      };
      return {
        quantity: item.amount,
        product_id: item.item.id,
        price: isHigherValue == true ? list[index] : item.item.sale_price,
        obs: item.observation,
        complements:
          verifyIsRedo().length > 0
            ? verifyIsRedo().map((complement, index) => {
                const ComplementId = () => {
                  if (complement.product_complement) {
                    return complement.product_complement.id;
                  }

                  if (complement.deliveryComplementId) {
                    return complement.deliveryComplementId;
                  }
                };
                return {
                  quantity: complement.quantity,
                  product_complement_id: ComplementId(),
                  higherValue: complement.higherValue,
                };
              })
            : item.complements,
      };
    });

    const verifyPaymentForm = () => {
      return paymentForm && printTotalValue() > 0 ? paymentForm.id : null;
    };

    const dataOrder = {
      neighborhood_id: orderMode === "0" ? null : neighborhood.id,
      company_id: companyData.id,
      total_order: calculateTotalBag(),
      change: changeMoney,
      is_free_shipping: pickupOnEstablishment,
      is_voucher: voucherSelected,
      customer_id: user.id,
      form_of_payment_id: verifyPaymentForm(),
      latitude: latitude,
      longitude: longitude,
      accuracy: parseInt(accuracy),
      items: items,
      discount_coupon_id: null,
    };

    const dataOrderCard = {
      neighborhood_id: orderMode === "0" ? null : neighborhood.id,
      company_id: companyData.id,
      total_order: calculateTotalBag(),
      change: changeMoney,
      is_free_shipping: pickupOnEstablishment,
      is_voucher: voucherSelected,
      customer_id: user.id,
      form_of_payment_id: verifyPaymentForm(),
      latitude: latitude,
      longitude: longitude,
      accuracy: parseInt(accuracy),
      items: items,
      discount_coupon_id: null,
      document: creditCard?.cpf,
      card_number: creditCard?.card_number,
      holder: creditCard?.holder,
      expiration_year: creditCard?.expiration_year,
      expiration_month: creditCard?.expiration_month,
      cvc: creditCard?.cvc,
      brand: creditCard?.brand,
    };

    return apiDelivery
      .post(
        `${company}/order`,
        useCard && paymentForm?.activate_acquirer ? dataOrderCard : dataOrder
      )
      .then(({ data: { response } }) => {
        setLoading(false);
        let timerInterval;
        let timeSecontActual = companyData.time_market_self;
        setLoading(false);
        if (honestMarket) {
          sendNotification(companyData.id);
          if (
            paymentForm !== null &&
            Helper.sanitizeString(paymentForm?.description).includes("pix")
          ) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
            <h5>${response.pix_key}</h5>
          `,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed && isExternal) {
                copy(response.pix_key);
                Swal.fire({
                  html: `
                  <h3>Para abrir a geladeira digite o código abaixo.</h3>
                  <h1><bold>${response.code_market_self}#</bold></h1>
                    <div class="wrap-circles">
                    <div class="circle per-0">
                      <div class="inner"><b>${timeSecontActual}</b></div>
                    </div>
      
                  <div class="tutorial-market-self">Ao digitar o código numérico e # a geladeira acenderá a luz verde, PORTA ABERTA Iniciará um beep e você terá 15 segundos para abrir a porta, após isso ela travará novamente.</div>
                  </div>
              `,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent =
                        timeSecontActual != 0
                          ? parseInt(
                              timeSecontActual != 0
                                ? (timeSecontActual = timeSecontActual - 1)
                                : 0
                            )
                          : "OK";
                      if (timeSecontActual == 0) {
                        Swal.hideLoading();
                      }
                      document.querySelector(
                        ".circle"
                      ).style.backgroundImage = `conic-gradient(rgb(255, 136, 0) ${
                        (timeSecontActual * 100) / companyData.time_market_self
                      }%, rgb(181, 179, 179)  0)`;
                    }, 1000);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                  }
                });
              }
            });
          } else {
            setLoading(false);
            Swal.fire({
              html: `<h3>Compra efetuada com sucesso!</h3>`,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed && isExternal) {
                Swal.fire({
                  html: `
                  <h3>Para abrir a geladeira digite o código abaixo.</h3>
                  <h1><bold>${response.code_market_self}#</bold></h1>
                    <div class="wrap-circles">
                    <div class="circle per-0">
                      <div class="inner"><b>${timeSecontActual}</b></div>
                    </div>
      
                  <div class="tutorial-market-self">Ao digitar o código numérico e # a geladeira acenderá a luz verde, PORTA ABERTA Iniciará um beep e você terá 15 segundos para abrir a porta, após isso ela travará novamente.</div>
                  </div>
              `,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent =
                        timeSecontActual != 0
                          ? parseInt(
                              timeSecontActual != 0
                                ? (timeSecontActual = timeSecontActual - 1)
                                : 0
                            )
                          : "OK";
                      if (timeSecontActual == 0) {
                        Swal.hideLoading();
                      }
                      document.querySelector(
                        ".circle"
                      ).style.backgroundImage = `conic-gradient(rgb(255, 136, 0) ${
                        (timeSecontActual * 100) / companyData.time_market_self
                      }%, rgb(181, 179, 179)  0)`;
                    }, 1000);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                  }
                });
              }
            });
          }
        } else {
          setLoading(false);
          sendNotification(companyData.id);
          if (paymentForm !== null && Helper.sanitizeString(paymentForm.description).includes("pix")) {
            copy(response.pix_key);
            Swal.fire({
              html: `<h3>Seu pix foi gerado e copiado com sucesso! cole o link abaixo para app do seu banco!</h3>
            <h5>${response.pix_key}</h5>
          `,
              icon: "success",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                copy(response.pix_key);
                msgWhats({
                  response,
                  bag,
                  companyData,
                  voucherSelected,
                  printTotalValue,
                  printVoucherValue,
                  calculateTotalItems,
                  totalVoucher,
                  paymentForm,
                  user,
                  changeMoney,
                  neighborhood,
                  orderMode,
                  changeOrderMode,
                  getOrderCode,
                  isFreeShipping,
                });
              }
            });
          } else {
            setLoading(false);
            msgWhats({
              response,
              bag,
              companyData,
              voucherSelected,
              printTotalValue,
              printVoucherValue,
              calculateTotalItems,
              totalVoucher,
              paymentForm,
              user,
              changeMoney,
              neighborhood,
              orderMode,
              changeOrderMode,
              getOrderCode,
              isFreeShipping,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.response[0] == "duplicate order") {
          return Swal.fire({
            html: `
              <h5>Aguarde um momento para comprar novamente o mesmo produto!</h5>
          `,
            icon: "info",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
        if (
          error.response.data.response[0] ==
          "Erro ao processar pagamento, tente novamente"
        )
          return alert("Erro ao processar pagamento, tente novamente");
      });
  }

  function calculateTotalBag() {
    const priceItems = calculateTotalItems();

    if (
      neighborhood != null &&
      orderMode === "1" &&
      !isFreeShipping &&
      voucherSelected === false
    )
      return priceItems;

    return priceItems;
  }

  function calculateTotalItems() {
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });

    if (list.length > 0) {
      const value = list.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
      return value;
    }
    return 0;
  }

  function printTotalValue() {
    if (
      voucherSelected === true &&
      isFreeShipping &&
      totalVoucher > calculateTotalBag()
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      totalVoucher <= calculateTotalBag()
    ) {
      return calculateTotalBag() - totalVoucher;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher > calculateTotalBag()
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher <= calculateTotalBag()
    ) {
      return calculateTotalBag() - totalVoucher;
    }
    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher >= calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return 0;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return (
        calculateTotalBag() - totalVoucher + parseFloat(neighborhood.price)
      );
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalBag() + parseFloat(neighborhood.price)
    ) {
      return (
        calculateTotalBag() - totalVoucher + parseFloat(neighborhood.price)
      );
    }

    if (voucherSelected === false && !isFreeShipping && orderMode === "1") {
      return calculateTotalBag() + parseFloat(neighborhood.price);
    }

    if (voucherSelected === false) {
      return calculateTotalBag();
    }
  }

  function printVoucherValue() {
    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher > calculateTotalItems() + parseFloat(neighborhood.price)
    ) {
      return calculateTotalItems() + parseFloat(neighborhood.price);
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode === "1" &&
      totalVoucher < calculateTotalItems() + parseFloat(neighborhood.price)
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      totalVoucher > calculateTotalItems()
    ) {
      return calculateTotalItems();
    }

    if (
      voucherSelected === true &&
      !isFreeShipping &&
      orderMode !== "1" &&
      calculateTotalItems() >= totalVoucher
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      calculateTotalItems() >= totalVoucher
    ) {
      return totalVoucher;
    }

    if (
      voucherSelected === true &&
      isFreeShipping &&
      calculateTotalItems() < totalVoucher
    ) {
      return calculateTotalItems();
    }

    return calculateTotalItems();
  }

  async function sendOrder() {
    sendOrderCode().finally(() => {
      clearVoucher();
      clearBag();
      setUseCard(false);
      setCreditCard([]);
      if (isEnvProduction()) {
        history.push(`/`);
      } else {
        history.push(`/${companyData.url}`);
      }
    });
  }

  function handleOrder() {
    toast.dismiss();

    if (paymentForm?.activate_acquirer && useCard) {
      if (!user.email) {
        return toast.dark("Email do usuario obrigatório");
      }
    }

    if (useCard && !Boolean(creditCard)) {
      return toast.dark("Adicione um cartão");
    }

    if (user == null) {
      return toast.dark("Informe os seus dados");
    }

    if (orderMode == null || orderMode === 0 || orderMode === 1) {
      return toast.dark("Informe o método de entrega");
    }

    if (
      (paymentForm == null && voucherSelected === false) ||
      (paymentForm === null &&
        voucherSelected === true &&
        printTotalValue() > 0)
    ) {
      return toast.dark("Informe a forma de pagamento");
    }

    setConfirmation(true);
  }

  const acquirer_pix = companyData.acquirer;
  const credit_card = companyData.acquirer;

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <Preloader status={loading} />
      <View style={styles.container}>
        <NewCard
          toggleVisible={() => setIsVisibleNewCards(!isVisibleNewCard)}
          visible={isVisibleNewCard}
          setSearchCard={setSearchCard}
        />
        <FormSign
          company={companyData}
          visible={visibleFormSign}
          onClose={() => {
            setVisibleFormSign(false);
          }}
          history={history}
        />
        <ModalChange printVoucherValue={printVoucherValue()} />
        <OptionsPayment
          visible={visibleModalPayment}
          onClose={() => setVisibleModalPayment(false)}
        />
        <ModalConfirmationOrder
          visible={confirmation}
          onClose={() => setConfirmation(false)}
          order={() => sendOrder()}
        />
        <OptionItemModal
          product={product}
          navigation={navigation}
          onEditItem={() => {
            if (isEnvProduction()) {
              history.push(`/product/`, {
                id: product.id,
                amountSelected: product.amount,
                item: product.item,
                selectedComplements: product.complements,
                observationItem: product.observation,
              });
            } else {
              history.push(`/${company}/product`, {
                id: product.id,
                amountSelected: product.amount,
                item: product.item,
                selectedComplements: product.complements,
                observationItem: product.observation,
              });
            }
          }}
          onRemove={() => {
            removeItem(product.id);
            if (isEnvProduction()) {
              history.push(`/`, {
                id: product.id,
                amountSelected: product.amount,
              });
            } else {
              history.push(`/${company}`, {
                id: product.id,
                amountSelected: product.amount,
              });
            }
          }}
          onClose={() => {
            setProduct(null);
          }}
        />
        {!loading ? <NavBar history={history} /> : ""}
        <ScrollView style={styles.content}>
          <TouchableOpacity onPress={() => setVisibleFormSign(true)}>
            <View style={styles.header}>
              <Text style={{ fontSize: 16, fontWeight: "600" }}>
                Perfil
                <AiFillEdit
                  style={{ float: "right", color: buttonColor, fontSize: 25 }}
                />
              </Text>
              <Text style={styles.textInput}>
                Nome: {user != null ? user.name : ""}
              </Text>
              <Text style={styles.textInput}>
                Telefone: {user != null ? user.phone : ""}
              </Text>
              <Text style={styles.textInput}>
                E-mail: {user != null ? user.email : ""}
              </Text>
            </View>
          </TouchableOpacity>
          {!honestMarket ?
           <View style={styles.header}>
            <TouchableOpacity onPress={() => setVisibleFormSign(true)}>
              <Text
                style={{ fontSize: 16, fontWeight: "600", marginBottom: 20 }}
              >
                Entrega
                <AiFillEdit
                  style={{ float: "right", color: buttonColor, fontSize: 25 }}
                />
              </Text>
            </TouchableOpacity>
            <SelectDeliveryMethod onChange={() => changeOrderMode()} />
          </View>
           : 
            ''
          }
          <View style={styles.containerPaymentForm}>
              <TouchableOpacity
                onPress={() => {
                  setVisibleModalPayment(true);
                }}
              >
                <View style={styles.btnSelectPaymentForm}>
                  <Text style={styles.titlePaymentFormText}>
                    Formas de pagamento
                  </Text>
                  <Text
                    style={[
                      styles.titleSwitchPaymentForm,
                      { color: buttonColor },
                    ]}
                    numberOfLines={1}
                  >
                    {paymentForm != null
                      ? paymentForm.description
                      : "Escolher"}
                  </Text>
                </View>
              </TouchableOpacity>
            {useCard && (
              <View style={styles.containerPaymentFormCard}>
                <CardPayment creditCard={creditCard} />
              </View>
            )}
          </View>
          <View style={styles.containerEstablishment}>
            <Text style={{ fontSize: 15, fontWeight: "600" }}>Pedido</Text>
          </View>
          <View style={styles.listItens}>
            {bag.map(
              (
                { item, amount, complements, id, observation, higherValue },
                index
              ) => {
                const listComplements = [];
                complements.map((group) => {
                  if (group.product_complement) {
                    let objectSelected = listComplements.find((object) => {
                      return (
                        object.complement.id === group.product_complement.id
                      );
                    });

                    if (objectSelected != null) {
                      objectSelected.amount++;
                    } else {
                      listComplements.push({
                        complement: group.product_complement,
                        amount: 1,
                      });
                    }
                  }
                  group.items &&
                    group.items.map((item) => {
                      let objectSelected = listComplements.find((object) => {
                        return object.complement.id === item.id;
                      });

                      if (objectSelected != null) {
                        objectSelected.amount++;
                      } else {
                        listComplements.push({ complement: item, amount: 1 });
                      }
                    });
                });

                return (
                  <View key={index} style={styles.containerItem}>
                    <View style={styles.contentTitleItem}>
                      <View style={styles.titleItem}>
                        <Text style={styles.titleAmountItem}>{amount}x </Text>
                        <Text style={styles.titleItemText}>{item.name}</Text>
                        <Text style={styles.titleAmountItem}>
                          R${" "}
                          {Helper.maskMoney(
                            Helper.calculateValueItem(item, amount, complements)
                          )}
                        </Text>
                      </View>
                      {listComplements.map(({ complement, amount }) => {
                        return (
                          <View style={styles.titleItem} key={complement.id}>
                            <Text style={styles.textComplementItem}>
                              - {amount}x {complement.name}
                            </Text>
                          </View>
                        );
                      })}
                      {observation.length > 0 ? (
                        <View style={styles.titleItem}>
                          <Text style={styles.textComplementItem}>
                            Observação: {observation}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                    <TouchableOpacity
                      style={styles.btnOptionItem}
                      onPress={() => {
                        setProduct({
                          id,
                          item,
                          amount,
                          complements,
                          observation,
                        });
                      }}
                    >
                      <FaEllipsisV />
                    </TouchableOpacity>
                  </View>
                );
              }
            )}
          </View>
          <View style={styles.containerTotals}>
            <View style={styles.contentItemTotals}>
              <Text style={styles.subtotalsText}>Subtotal</Text>
              <Text style={styles.subtotalsText}>
                R$ {Helper.maskMoney(calculateTotalItems())}
              </Text>
            </View>
            {neighborhood != null && orderMode === "1" && !isFreeShipping && (
              <View style={styles.contentItemTotals}>
                <Text style={styles.subtotalsText}>Entrega padrão</Text>
                <Text style={styles.subtotalsText}>
                  {" "}
                  R$ {Helper.maskMoney(parseFloat(neighborhood.price))}
                </Text>
              </View>
            )}

            {voucherSelected === true && (
              <View style={styles.contentItemTotals}>
                {totalVoucher > 0 && voucherSelected === true && (
                  <Text style={styles.subtotalsText}>Voucher</Text>
                )}

                <Text style={styles.subtotalsText}>
                  {"- R$ " + Helper.maskMoney(printVoucherValue())}
                </Text>
              </View>
            )}
            <View style={styles.contentItemTotals}>
              <Text style={styles.totalsText}>Total</Text>
              <Text style={styles.totalsText}>
                {"R$ " + Helper.maskMoney(printTotalValue())}
              </Text>
            </View>
          </View>
          <Change />
          <View style={styles.containerVouecher}>
            <View style={styles.btnSelectPaymentForm}>
              <Text
                style={{ fontSize: 16, fontWeight: "600", marginBottom: 20 }}
              >
                Voucher
              </Text>
              <Voucher
                visible={visibleModalVoucher}
                onClose={() => setVisibleModalVoucher(false)}
              />
              <TouchableOpacity
                onPress={() => {
                  setVisibleModalVoucher(true);
                }}
              >
                <Text
                  style={[
                    styles.titleSwitchPaymentForm,
                    { color: buttonColor },
                  ]}
                  numberOfLines={1}
                >
                  {voucherSelected
                    ? "Voucher Selecionado!"
                    : "Selecione um voucher"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <View
          style={[
            styles.footer,
            loading ? { display: "none" } : { display: "block" },
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              handleOrder();
            }}
          >
            <View
              style={[
                styles.btnFinish,
                { backgroundColor: buttonColor, color: buttonTextColor },
              ]}
            >
              <Text style={[styles.btnFinishText]}>Continuar</Text>
            </View>
          </TouchableOpacity>
        </View>
        <ToastContainer hideProgressBar={true} pauseOnHover />
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: windowHeight,
  },
  content: {
    flex: 1,
    backgroundColor: "#ddd",
  },
  footer: {
    backgroundColor: "#fff",
    borderTopColor: "#ddd",
    borderTopWidth: 1,
  },
  header: {
    marginTop: 10,
    padding: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
  },
  btnLocation: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 5,
    alignItems: "center",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
  iconLocal: {
    width: 150,
    left: -20,
  },
  contentLocal: {
    flex: 1,
  },
  titleDeliveryLocal: {
    fontSize: 14,
    color: "#555",
    bottom: -25,
    left: 15,
  },
  titleLocal: {
    fontSize: 18,
  },
  contentDescriptionInfoDelivery: {
    padding: 10,
  },
  descriptionTitleLocal: {
    fontSize: 14,
  },
  subtitleLocal: {
    fontSize: 13,
    color: "#666",
  },
  listItens: {
    flex: 1,
    backgroundColor: "#fff",
  },
  containerItem: {
    display: "flex",
    flexDirection: "row",
    marginHorizontal: 15,
    paddingVertical: 20,
    borderColor: "#ddd",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  contentTitleItem: {
    flex: 1,
  },
  titleItem: {
    display: "flex",
    flexDirection: "row",
  },
  titleItemText: {
    fontSize: 16,
    flex: 1,
    left: 10,
    marginRight: 25,
  },
  titleAmountItem: {
    fontSize: 16,
  },
  textComplementItem: {
    fontSize: 11,
    color: "#999",
    marginLeft: 5,
  },
  btnOptionItem: {
    padding: 10,
  },
  containerTotals: {
    padding: 15,
    backgroundColor: "#fff",
  },
  contentItemTotals: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtotalsText: {
    color: "#555",
  },
  totalsText: {
    fontSize: 18,
  },
  containerPaymentForm: {
    marginTop: 10,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  containerVouecher: {
    marginTop: 10,
    marginBottom: 30,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  containerPaymentFormCard: {
    marginBottom: 5,
    backgroundColor: "#fff",
  },
  titlePaymentForm: {
    fontSize: 18,
    paddingVertical: 20,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
  },
  btnSelectPaymentForm: {
    display: "flex",
    paddingVertical: 30,
  },
  titlePaymentFormText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  titleSwitchPaymentForm: {
    fontSize: 15,
    color: Colors.PRIMARY,
  },
  btnFinish: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: 15,
    color: "#fff",
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    justifyContent: "center",
  },
  btnFinishText: {
    color: "#fff",
    fontSize: 18,
  },
  containerEstablishment: {
    marginTop: 10,
    padding: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  textInput: {
    fontSize: 16,
    paddingTop: 5,
    color: "#555",
  },
});
