import React from 'react';
import InputMask from 'react-input-mask'
import '../InputPhoneMask/Style.css'

export default function components({value, onChangeText}) {
  return (
    <InputMask 
      type="tel"
      value={value}  
      mask="(99) 99999-9999"
      maskchar={null}
      onChange={(event) => {onChangeText(event.target.value)}} 
      style={{
            borderColor: "gray",
            borderWidth: 1, borderRadius: 5, 
            padding: 10
            }} 
      />
  );
}

