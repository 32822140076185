import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes";
import { ServiceWorkerProvider } from "./contexts/serviceworker";
import { AuthProvider } from "./contexts/auth";
import { OrderServiceProvider } from "./contexts/orderService";
import "./services/Firebase";
import { ColorProvider } from "./contexts/colors";

ReactDOM.render(
  <React.StrictMode>
    <ColorProvider>
      <ServiceWorkerProvider>
        <AuthProvider>
          <OrderServiceProvider>
            <Routes />
          </OrderServiceProvider>
        </AuthProvider>
      </ServiceWorkerProvider>
    </ColorProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
