import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Colors from "../../components/Colors";
import { useOrderService } from "../../contexts/orderService";
import { FiShoppingBag } from "react-icons/fi";
import Helper from "../../utils/Helper";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useColor } from "../../contexts/colors";

export default function Bag({ history }) {
  const { bag } = useOrderService();
  let company = getSubDomain();
  const {buttonColor, buttonTextColor} = useColor();

  if (bag.length === 0) return null;

  let price = 0;

  bag.map(({ item, amount, complements, higherValue }) => {
    price += Helper.calculateValueItem(item, amount, complements);
    return [];
  });

  return (
    <TouchableOpacity
      onPress={() => {
        if (isEnvProduction()) {
          history.push(`/detailOrder/`, { bag });
        } else {
          history.push(`/${company}/detailOrder/`, { bag });
        }
      }}
    >
      <View style={[styles.container, {backgroundColor: buttonColor, color: buttonTextColor}]}>
        <Text style={{ fontSize: 30, color: buttonTextColor }}>
          <FiShoppingBag />
        </Text>
        <Text style={[styles.text, {color: buttonTextColor}]}>Sacola</Text>
        <Text style={[styles.price, {color: buttonTextColor}]}>R$ {Helper.maskMoney(price)}</Text>
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    width: "100%",
    padding: 15,
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    flexDirection: "row",
    bottom: 0,
  },
  text: {
    color: "#fff",
    fontSize: 16,
    position: "absolute",
    textAlign: "center",
    left: 0,
    right: 0,
    fontWeight: "bold",
  },
  price: {
    color: "#fff",
    fontSize: 16,
  },
});
